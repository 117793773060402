import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { rhythm, scale } from "../utils/typography"

import favicon from "../images/favicon.ico"
import Helmet from "react-helmet"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    const blogPath = `${__PATH_PREFIX__}/blog/`
    const talkPath = `${__PATH_PREFIX__}/talk/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <nav
          style={{
            marginBottom: 20,
          }}
        >
          <Link
            style={{
              ...scale(0.15),
              fontFamily: `Montserrat, sans-serif`,
              marginTop: 0,
              decoration: "none",
            }}
            to={`/`}
          >
            {title}
          </Link>
          <div style={{ float: "right" }}>
            <Link
              style={{
                ...scale(0.15),
                fontFamily: `Montserrat, sans-serif`,
                marginTop: 0,
              }}
              to={`/talk/`}
            >
              Talks
            </Link>
            &nbsp;&nbsp;
            <Link
              style={{
                ...scale(0.15),
                fontFamily: `Montserrat, sans-serif`,
                marginTop: 0,
              }}
              to={`/blog/`}
            >
              Blog
            </Link>
          </div>
        </nav>
      )
    } else if (location.pathname === blogPath) {
      header = (
        <nav
          style={{
            marginBottom: 20,
          }}
        >
          <Link
            style={{
              ...scale(0.15),
              fontFamily: `Montserrat, sans-serif`,
              marginTop: 0,
            }}
            to={`/`}
          >
            {title}
          </Link>
          <div style={{ float: "right" }}>
            <Link
              style={{
                ...scale(0.15),
                fontFamily: `Montserrat, sans-serif`,
                marginTop: 0,
              }}
              to={`/talk/`}
            >
              Talks
            </Link>
          </div>
        </nav>
      )
    } else if (location.pathname === talkPath) {
      header = (
        <nav
          style={{
            marginBottom: 20,
          }}
        >
          <Link
            style={{
              ...scale(0.15),
              fontFamily: `Montserrat, sans-serif`,
              marginTop: 0,
            }}
            to={`/`}
          >
            {title}
          </Link>
          <div style={{ float: "right" }}>
            <Link
              style={{
                ...scale(0.15),
                fontFamily: `Montserrat, sans-serif`,
                marginTop: 0,
              }}
              to={`/blog/`}
            >
              Blog
            </Link>
          </div>
        </nav>
      )
    } else {
      header = (
        <nav
          style={{
            marginBottom: 20,
          }}
        >
          <Link
            style={{
              ...scale(0.15),
              fontFamily: `Montserrat, sans-serif`,
              marginTop: 0,
              decoration: "none",
            }}
            to={`/`}
          >
            {" "}
            {title}
          </Link>
          <div style={{ float: "right" }}>
            <Link
              style={{
                ...scale(0.15),
                fontFamily: `Montserrat, sans-serif`,
                marginTop: 0,
              }}
              to={`/talk/`}
            >
              Talks
            </Link>
            &nbsp;&nbsp;
            <Link
              style={{
                ...scale(0.15),
                fontFamily: `Montserrat, sans-serif`,
                marginTop: 0,
              }}
              to={`/blog/`}
            >
              Blog
            </Link>
          </div>
        </nav>
      )
    }
    return (
      <Wrapper>
        <Helmet>
          <link rel="icon" href={favicon} />
        </Helmet>
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(32),
            padding: `${rhythm(1.5)} ${rhythm(0.75)}`,
            backgroundColor: "rgb(250, 250, 250)",
          }}
        >
          <header>{header}</header>
          <main>{children}</main>
        </div>
        <Footer>
          © {new Date().getFullYear()}, Built with{" "}
          <a href="https://www.gatsbyjs.org/">Gatsby</a>
        </Footer>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  min-height: 100vh;
`

const Footer = styled.footer`
  text-align: center;
  margin: 24px;
`

export default Layout
